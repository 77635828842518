import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ContactBox = styled(Link)`
  display: block;
  text-decoration: none;
  margin-top: 16px;
  padding: 16px 8px;
  background-color: #fafafc;
  cursor: pointer;
  color: #121232;
`

const ContactSummary = ({ contact }) => (
  <ContactBox to={`/contacts/${contact.id}`}>
    {contact.data.firstName} {contact.data.lastName}
  </ContactBox>
)

ContactSummary.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired
  })
}

export default ContactSummary
