import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { gql, useApolloClient } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import useAccount from '../../hooks/useAccount'
import { deriveAccountKeys, unwrapAesKey } from '../../crypto'

const Container = styled.form`
  max-width: 320px;
`

const Instruction = styled.div`
  font-size: 18px;
  padding-bottom: 32px;
  line-height: 25px;
  text-align: center;
`

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #232735;
  display: block;
`

const Input = styled.input`
  display: block;
  padding: 12px 16px;
  font-family: inherit;
  margin-bottom: 32px;
  margin-top: 9px;
  height: 48px;
  width: 100%;
`

const Button = styled.button`
  background: #121232;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: inherit;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  border: 0;

  &:disabled {
    background: #efefef;
  }
`

const Center = styled.div`
  text-align: center;
`

const RequestError = styled.div`
  background-color: #f44336;
  padding: 16px;
  color: white;
  margin-bottom: 16px;
  border-radius: 2px;
  text-align: center;
`

const expectedErrors = ['Invalid email or password.']

const GET_SALT = gql`
  query getSalt($email: String!) {
    getSalt(email: $email)
  }
`

const LOGIN = gql`
  mutation login($email: String!, $accountToken: String!) {
    login(email: $email, accountToken: $accountToken) {
      authToken
      user {
        encryptionKey {
          encryptedData
          iv
        }
      }
    }
  }
`

function LoginForm() {
  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState()
  const { register, handleSubmit } = useForm()
  const { setAccount } = useAccount()
  const client = useApolloClient()
  const history = useHistory()

  async function onSubmit({ email, password }) {
    setRequestError(false)
    setLoading(true)

    try {
      const queryResult = await client.query({
        query: GET_SALT,
        variables: { email }
      })

      const salt = queryResult.data.getSalt

      const { accountKey, accountToken } = await deriveAccountKeys(
        password,
        salt
      )

      const mutationResult = await client.mutate({
        mutation: LOGIN,
        variables: { email, accountToken }
      })

      const { authToken, user } = mutationResult.data.login
      const { encryptionKey } = user

      const unwrappedKey = await unwrapAesKey(encryptionKey, accountKey)

      setAccount(authToken, unwrappedKey)
      history.push('/')
    } catch (e) {
      console.error('Error occurred:', e)
      if (expectedErrors.includes(e.message)) setRequestError(e.message)
      else setRequestError('Hm, an error occurred. Try again.')
      setLoading(false)
    }
  }

  return (
    <Container aria-labelledby="formtitle" onSubmit={handleSubmit(onSubmit)}>
      <Instruction id="form-title">Access your account</Instruction>
      <Label>
        Email
        <Input type="email" name="email" ref={register} />
      </Label>
      <Label>
        Password
        <Input type="password" name="password" ref={register} />
      </Label>
      {requestError && <RequestError role="alert">{requestError}</RequestError>}
      <Center>
        <Button disabled={loading}>
          Login
          {loading && (
            <span role="progressbar">
              <FontAwesomeIcon icon={faSpinner} spin />
            </span>
          )}
        </Button>
      </Center>
    </Container>
  )
}

export default LoginForm
