import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import useAccount from '../../hooks/useAccount'
import { encryptObject } from '../../crypto'

const CREATE_NOTE = gql`
  mutation createNote($contact: ID!, $note: NoteInput!) {
    createNote(contact: $contact, note: $note) {
      id
      data {
        encryptedData
        iv
      }
    }
  }
`

function AddNoteForm({ contact }) {
  const [createNote, { loading }] = useMutation(CREATE_NOTE, { update })
  const [requestError, setRequestError] = useState()
  const { register, handleSubmit, reset } = useForm()
  const { getAccount } = useAccount()

  function update(cache, { data: { createNote } }) {
    const ref = cache.writeFragment({
      data: createNote,
      fragment: gql`
        fragment NewNote on Note {
          id
          data {
            encryptedData
            iv
          }
        }
      `
    })

    cache.modify({
      id: cache.identify({ id: contact, __typename: 'Contact' }),
      fields: {
        notes: notes => notes.concat(ref)
      }
    })
  }

  async function onSubmit(noteFields) {
    try {
      setRequestError(false)
      const { encryptionKey } = await getAccount()
      const data = await encryptObject(noteFields, encryptionKey)
      await createNote({
        variables: { contact, note: { data } }
      })
      reset()
    } catch (e) {
      console.error('Error occurred:', e)
      setRequestError('Hm, an error occurred. Try again.')
    }
  }

  return (
    <form aria-labelledby="formTitle" onSubmit={handleSubmit(onSubmit)}>
      <div id="formTitle">Add note</div>
      <label>
        Content
        <input type="text" name="content" ref={register} />
      </label>
      {requestError && <div role="alert">{requestError}</div>}
      <button disabled={loading}>
        Add Note
        {loading && (
          <span role="progressbar">
            <FontAwesomeIcon icon={faSpinner} spin />
          </span>
        )}
      </button>
    </form>
  )
}

AddNoteForm.propTypes = {
  contact: PropTypes.string.isRequired
}

export default AddNoteForm
