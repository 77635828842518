import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useAccount from '../../hooks/useAccount'
import CreateContactPage from '../pages/CreateContactPage'
import ContactPage from '../pages/ContactPage'
import SignupPage from '../pages/SignupPage'
import LogoutPage from '../pages/LogoutPage'
import LoginPage from '../pages/LoginPage'
import HomePage from '../pages/HomePage'

function App() {
  const { isLoggedIn } = useAccount()
  return (
    <Switch>
      <Route path="/" exact>
        {isLoggedIn ? <HomePage /> : <LoginPage />}
      </Route>
      <Route path="/contacts/new">
        {isLoggedIn ? <CreateContactPage /> : <Redirect to="/" />}
      </Route>
      <Route path="/contacts/:id">
        {isLoggedIn ? <ContactPage /> : <Redirect to="/" />}
      </Route>
      <Route path="/signup">
        {isLoggedIn ? <Redirect to="/" /> : <SignupPage />}
      </Route>
      <Route path="/logout">
        <LogoutPage />
      </Route>
    </Switch>
  )
}

export default App
