import React from 'react'
import styled from 'styled-components'
import LoginForm from '../login/LoginForm'

const Background = styled.div`
  background-color: #c4c4c4;
  height: 100vh;
  width: 100%;
`

const Title = styled.div`
  text-align: center;
  line-height: 64px;
  font-size: 44px;
  padding-top: 21px;
  padding-bottom: 19px;
  font-weight: 600;
`

const CenterBox = styled.div`
  background-color: white;
  padding: 32px 16px;
  margin: 0 auto;
  width: 320px;
`

const LoginPage = () => (
  <Background>
    <Title>Parobi</Title>
    <CenterBox>
      <LoginForm />
    </CenterBox>
  </Background>
)

export default LoginPage
