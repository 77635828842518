import { useState, useEffect } from 'react'
import useAccount from '../hooks/useAccount'
import { decryptObject } from '../crypto'

const cache = new WeakMap()

function useDecryption(query) {
  const [state, setState] = useState({})
  const { getAccount } = useAccount()

  useEffect(() => {
    let mounted = true

    async function processQueryData() {
      try {
        async function iterate(input) {
          if (Array.isArray(input)) {
            return await Promise.all(input.map(iterate))
          } else if (typeof input === 'object') {
            return Object.fromEntries(
              await Promise.all(
                Object.entries(input).map(async ([k, v]) => [
                  k,
                  v.__typename === 'EncryptedData'
                    ? await decryptObject(v, encryptionKey)
                    : await iterate(v)
                ])
              )
            )
          } else {
            return input
          }
        }

        const { encryptionKey } = await getAccount()
        const data = await iterate(query.data)
        cache.set(query.data, data)

        if (!mounted) return
        setState({ data })
      } catch (error) {
        setState({ error })
      }
    }

    if (query.data && !cache.has(query.data)) {
      processQueryData()
    }

    return () => {
      mounted = false
    }
  }, [query.data, getAccount])

  const cached = query.data && cache.get(query.data)
  const error = query.error || state.error
  const data = state.data

  if (cached) return { data: cached, loading: false, error: null }
  if (error) return { data: null, loading: false, error }
  if (data) return { data, loading: false, error: null }
  return { data: null, loading: true, error: null }
}

export default useDecryption
