async function deriveAccountKeys(
  password,
  salt = window.crypto.getRandomValues(new Uint8Array(32))
) {
  const enc = new TextEncoder()

  if (typeof salt === 'string') {
    salt = convertBase64ToBuffer(salt)
  }

  /* This function derives an encryption key and account token from a given
   * password. If this is for login, you must provide the same salt that was
   * generated at registration time. If this is for registration, we generate
   * a new salt and return it.
   *
   * The first thing we do is import our string password as a CryptoKey */

  const keyMaterial = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(password),
    { name: 'PBKDF2' },
    false,
    ['deriveBits']
  )

  /* Next we derive the PBKDF2 master key. PBKDF2 is a key derivation function,
   * it strengthens a possibly weak key such as a password and allows us to use
   * it for sensitive cryptographic operations. In this particular case, we
   * generate raw bits instead of a CryptoKey because we're going to use them as
   * source material for two new keys. We do not EVER use this directly. */

  const masterBits = await window.crypto.subtle.deriveBits(
    {
      name: 'PBKDF2',
      salt,
      iterations: 256000,
      hash: 'SHA-256'
    },
    keyMaterial,
    256
  )

  /* We now import the bits as a CryptoKey, just like we did with the password.
   * However, now these bits are a much more robust key than the raw password.
   * Again, this key is only permitted for derivation and not encryption. */

  const masterKey = await window.crypto.subtle.importKey(
    'raw',
    masterBits,
    { name: 'HKDF' },
    false,
    ['deriveKey', 'deriveBits']
  )

  /* Here we derive the account token from the master key using HKDF. The `info`
   * parameter of HKDF allows us to take a single input source (the master key)
   * and generate two fully unrelated, irreversable, and reproducible outputs.
   * We take advantage of this to generate 1) an account token and 2) the
   * encryption key itself.
   *
   * We ONLY use the account token as a bearer token to prove to the server that
   * we are who we say we are without disclosing the password itself. It has no
   * cryptographic uses. */

  const accountToken = await window.crypto.subtle.deriveBits(
    {
      name: 'HKDF',
      salt,
      info: enc.encode('account-token'),
      hash: 'SHA-256'
    },
    masterKey,
    256
  )

  /* This is the other component that we derive from the master key using HKDF.
   * We use a different `info` parameter, so we get a different output. This is
   * not EVER presented to the server and is set to be non-exportable. We use
   * this encryption key to wrap the user's data encryption key, so that we can
   * safely send that key to the server knowing the server cannot decrypt it
   * without this encryption key. */

  const accountKey = await window.crypto.subtle.deriveKey(
    {
      name: 'HKDF',
      salt,
      info: enc.encode('encryption-key'),
      hash: 'SHA-256'
    },
    masterKey,
    { name: 'AES-GCM', length: 256 },
    isTest,
    ['wrapKey', 'unwrapKey']
  )

  const accountTokenBase64 = convertBufferToBase64(accountToken)
  const saltBase64 = convertBufferToBase64(salt)

  return {
    salt: saltBase64,
    accountToken: accountTokenBase64,
    accountKey
  }
}

async function generateAesKey() {
  /* This function generates a new, random AES symmetric key. It returns a
   * CryptoKey object which can be used for AES256 encryption / decryption in
   * GCM mode. We use this to generate the data encryption key. We later encrypt
   * the key itself using the account encryption key. This way, we can change
   * the password later on if needed and rewrap our data encryption key. */
  return await window.crypto.subtle.generateKey(
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  )
}

async function wrapAesKey(key, wrappingKey) {
  /* This function takes one AES key, and another AES key (called the wrapping
   * key in this context). It returns the first key, encrypted with the wrapping
   * key. Since AES256 in GCM mode requires an initialization vector, it also
   * returns the IV. The IV can be shared publicly and must not be reused,
   * similar to a salt. */
  const iv = window.crypto.getRandomValues(new Uint8Array(12))
  const wrappedKey = await window.crypto.subtle.wrapKey(
    'jwk',
    key,
    wrappingKey,
    { name: 'AES-GCM', iv }
  )

  const wrappedKeyBase64 = convertBufferToBase64(wrappedKey)
  const ivBase64 = convertBufferToBase64(iv)

  return {
    encryptedData: wrappedKeyBase64,
    iv: ivBase64
  }
}

async function unwrapAesKey(wrappedKey, wrappingKey, extractable = isTest) {
  /* This function takes a wrapped AES symmetric key, and another AES symmetric
   * key (called the wrapping key in this context). It returns a decrypted AES
   * symmetric key. This function is the opposite of `wrapAesKey`. */
  const ivBuffer = convertBase64ToBuffer(wrappedKey.iv)
  const wrappedKeyBuffer = convertBase64ToBuffer(wrappedKey.encryptedData)
  const key = await window.crypto.subtle.unwrapKey(
    'jwk',
    wrappedKeyBuffer,
    wrappingKey,
    { name: 'AES-GCM', iv: ivBuffer },
    { name: 'AES-GCM', length: 256 },
    extractable,
    ['encrypt', 'decrypt']
  )
  return key
}

async function encryptObject(object, key) {
  /* This function takes a object (which must be serializable) and an AES
   * symmetric key. It will encrypt the object with AES256 in GCM mode using
   * the key provided. Since AES256 in GCM mode requires an initialization
   * vector, it also returns the IV. The IV can be shared publicly and must not
   * be reused, similar to a salt. This function can be undone with
   * `decryptObject`. */
  const enc = new TextEncoder()
  const iv = window.crypto.getRandomValues(new Uint8Array(12))
  const buffer = enc.encode(JSON.stringify(object))
  const encryptedBuffer = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    buffer
  )
  return {
    encryptedData: convertBufferToBase64(encryptedBuffer),
    iv: convertBufferToBase64(iv)
  }
}

async function decryptObject(encryptedObject, key) {
  /* This function takes an encrypted object and an AES symmetric key. It will
   * decrypt the object using the symmetric key and IV, and return the decrypted
   * object. This function is the opposite of `encryptObject`. */
  const dec = new TextDecoder()
  const ivBuffer = convertBase64ToBuffer(encryptedObject.iv)
  const encryptedBuffer = convertBase64ToBuffer(encryptedObject.encryptedData)
  const buffer = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: ivBuffer },
    key,
    encryptedBuffer
  )
  return JSON.parse(dec.decode(buffer))
}

function convertBufferToBase64(arrayBuffer) {
  /* Simple helper function to convert an ArrayBuffer to base 64. The opposite
   * of this is `convertBase64ToBuffer`. */
  return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
}

function convertBase64ToBuffer(base64) {
  /* Simple helper function to convert a base 64 to an ArrayBuffer. The opposite
   * of this is `convertBufferToBase64`. */
  return Uint8Array.from(atob(base64), c => c.charCodeAt(0))
}

function setTestMode(value) {
  /* While testing crypto code, we can call this function to enable marking the
   * keys as extractable by default. This is necessary for comparing keys. */
  if (process.env.NODE_ENV !== 'test') return
  isTest = value
}

let isTest = false

export {
  deriveAccountKeys,
  generateAesKey,
  wrapAesKey,
  unwrapAesKey,
  encryptObject,
  decryptObject,
  convertBufferToBase64,
  convertBase64ToBuffer,
  setTestMode
}
