import React from 'react'
import PropTypes from 'prop-types'

const Note = ({ content }) => <div>{content}</div>

Note.propTypes = {
  content: PropTypes.string.isRequired
}

export default Note
