import React, { useState } from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import ContactSummary from '../contacts/ContactSummary'
import useDecryption from '../../hooks/useDecryption'
import Loading from '../app/Loading'
import Error from '../app/Error'

const Input = styled.input`
  height: 48px;
  width: 100%;
  font-size: 14px;
  padding-left: 44px;
  font-family: inherit;
  color: #8b90a0;
`

const Container = styled.div`
  position: relative;
`

const Block = styled.div`
  position: absolute;
  background-color: white;
  height: 100vh;
  width: 100%;
`

const GET_CONTACTS = gql`
  query getContacts {
    getContacts {
      id
      data {
        encryptedData
        iv
      }
    }
  }
`

function SearchBox() {
  const { data, loading, error } = useDecryption(useQuery(GET_CONTACTS))
  const [matches, setMatches] = useState([])
  const [query, setQuery] = useState('')

  function onChange(e) {
    const query = e.target.value
    const regex = new RegExp(query, 'i')
    setMatches(
      query
        ? contacts.filter(c =>
            regex.test(`${c.fields.firstName} ${c.fields.lastName}`)
          )
        : []
    )
    setQuery(query)
  }

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  const contacts = data.getContacts

  return (
    <>
      <Input
        type="text"
        onChange={onChange}
        value={query}
        placeholder="Search contacts"
      />
      {matches.length !== 0 && (
        <Container>
          <Block>
            {matches.map(contact => (
              <ContactSummary key={contact.id} contact={contact} />
            ))}
          </Block>
        </Container>
      )}
    </>
  )
}

export default SearchBox
