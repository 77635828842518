import React from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import useAccount from '../../hooks/useAccount'
import dunbarGroups from '../../util/dunbarGroups'
import { encryptObject } from '../../crypto'

const SET_DATA = gql`
  mutation setData($contact: ID!, $data: EncryptedDataInput!) {
    setData(contact: $contact, data: $data) {
      id
      data {
        encryptedData
        iv
      }
    }
  }
`

function DunbarGroup({ contact }) {
  const [setData] = useMutation(SET_DATA)
  const { getAccount } = useAccount()

  async function handleChange(e) {
    const group = e.target.value
    const { encryptionKey } = await getAccount()
    const fields = { ...contact.data, group }
    const data = await encryptObject(fields, encryptionKey)
    await setData({ variables: { contact: contact.id, data } })
  }

  return (
    <select value={contact.data.group} onChange={handleChange}>
      {Object.entries(dunbarGroups).map(([k, v]) => (
        <option key={k} value={k}>
          {v}
        </option>
      ))}
    </select>
  )
}

DunbarGroup.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      group: PropTypes.string.isRequired
    }).isRequired
  })
}

export default DunbarGroup
