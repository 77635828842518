import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import useAccount from '../../hooks/useAccount'

function LogoutPage() {
  const { setAccount } = useAccount()
  const client = useApolloClient()

  useEffect(() => {
    client.clearStore()
    setAccount(null)
  }, [client, setAccount])

  return <Redirect to="/login" />
}

export default LogoutPage
