import JwtDecode from 'jwt-decode'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import history from '../history'

const TOKEN_CHECK_TIME = 60 * 1000
const uri = process.env.REACT_APP_API_URL

const httpLink = createHttpLink({ uri })
const authLink = setContext(attachHeader)

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

function attachHeader(request, context) {
  const token = window.localStorage.authToken
  return {
    ...context,
    headers: {
      ...context.headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
}

function isTokenValid() {
  const { exp } = JwtDecode(window.localStorage.authToken)
  const currentTime = new Date().getTime() / 1000
  // Check if token would be expired before next check, to ensure we don't make
  // any requests with an invalid token
  return !(currentTime + TOKEN_CHECK_TIME / 1000 > exp)
}

function checkToken() {
  if (window.localStorage.authToken && !isTokenValid()) {
    history.push('/logout')
  }
}

checkToken()

setInterval(checkToken, TOKEN_CHECK_TIME)

export default client
