import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { Router } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import AccountProvider from './providers/AccountProvider'
import App from './components/app/App'
import client from './graphql/client'
import history from './history'
import './global.css'

ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <AccountProvider>
          <App />
        </AccountProvider>
      </React.StrictMode>
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
)

serviceWorker.register()
