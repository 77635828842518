import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import useAccount from '../../hooks/useAccount'
import { deriveAccountKeys, generateAesKey, wrapAesKey } from '../../crypto'

const Container = styled.form`
  max-width: 320px;
`

const Instruction = styled.div`
  font-size: 18px;
  padding-bottom: 32px;
  line-height: 25px;
  text-align: center;
`

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #232735;
  display: block;
`

const Input = styled.input`
  display: block;
  padding: 12px 16px;
  font-family: inherit;
  margin-bottom: 32px;
  margin-top: 9px;
  height: 48px;
  width: 100%;
`

const Button = styled.button`
  background: #121232;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: inherit;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  border: 0;

  &:disabled {
    background: #efefef;
  }
`

const Center = styled.div`
  text-align: center;
`

const RequestError = styled.div`
  background-color: #f44336;
  padding: 16px;
  color: white;
  margin-bottom: 16px;
  border-radius: 2px;
  text-align: center;
`

const expectedErrors = [
  'There is already a user with this email.',
  'This account has not been invited.'
]

const SIGNUP = gql`
  mutation signup($user: UserInput!) {
    signup(user: $user) {
      authToken
    }
  }
`

function SignupForm() {
  const [signup, { loading }] = useMutation(SIGNUP)
  const [requestError, setRequestError] = useState()
  const { register, handleSubmit } = useForm()
  const { setAccount } = useAccount()
  const history = useHistory()

  async function onSubmit(values) {
    const { email, password } = values

    const { accountKey, accountToken, salt } = await deriveAccountKeys(password)

    const encryptionKey = await generateAesKey()
    const wrappedKey = await wrapAesKey(encryptionKey, accountKey)

    const user = {
      encryptionKey: wrappedKey,
      accountToken,
      email,
      salt
    }

    try {
      const result = await signup({ variables: { user } })
      const { authToken } = result.data.signup
      setAccount(authToken, encryptionKey)
      history.push('/')
    } catch (e) {
      console.error('Request error:', e.message)
      if (expectedErrors.includes(e.message)) setRequestError(e.message)
      else setRequestError('Hm, an error occurred. Try again.')
    }
  }

  return (
    <Container aria-labelledby="formtitle" onSubmit={handleSubmit(onSubmit)}>
      <Instruction id="form-title">Create an account</Instruction>
      <Label>
        Email
        <Input type="email" name="email" ref={register} />
      </Label>
      <Label>
        Password
        <Input type="password" name="password" ref={register} />
      </Label>
      {requestError && <RequestError role="alert">{requestError}</RequestError>}
      <Center>
        <Button disabled={loading}>
          Sign up
          {loading && (
            <span role="progressbar">
              <FontAwesomeIcon icon={faSpinner} spin />
            </span>
          )}
        </Button>
      </Center>
    </Container>
  )
}

export default SignupForm
