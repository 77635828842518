import React, { useState } from 'react'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import { set, get, del } from 'idb-keyval'
import AccountContext from '../contexts/AccountContext'

function AccountProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(!!window.localStorage.authToken)

  async function getAccount() {
    try {
      const { id } = jwtDecode(window.localStorage.authToken)
      const encryptionKey = await get('encryptionKey')

      setIsLoggedIn(true)

      return { id, encryptionKey }
    } catch {
      setIsLoggedIn(false)

      return {
        id: null,
        encryptionKey: null
      }
    }
  }

  async function setAccount(authToken, encryptionKey) {
    if (authToken === null) {
      setIsLoggedIn(false)
      delete window.localStorage.authToken
      await del('encryptionKey')
    } else {
      setIsLoggedIn(true)
      window.localStorage.authToken = authToken
      await set('encryptionKey', encryptionKey)
    }
  }

  return (
    <AccountContext.Provider value={{ getAccount, setAccount, isLoggedIn }}>
      {children}
    </AccountContext.Provider>
  )
}

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AccountProvider
