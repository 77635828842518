import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import useAccount from '../../hooks/useAccount'
import { encryptObject } from '../../crypto'

const Container = styled.form`
  max-width: 320px;
`

const Instruction = styled.div`
  font-size: 18px;
  padding-bottom: 32px;
  line-height: 25px;
  text-align: center;
`

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #232735;
  display: block;
`

const Input = styled.input`
  display: block;
  padding: 12px 16px;
  font-family: inherit;
  margin-bottom: 32px;
  margin-top: 9px;
  height: 48px;
  width: 100%;
`

const Button = styled.button`
  background: #121232;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: inherit;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  border: 0;

  &:disabled {
    background: #efefef;
  }
`

const Center = styled.div`
  text-align: center;
`

const RequestError = styled.div`
  background-color: #f44336;
  padding: 16px;
  color: white;
  margin-bottom: 16px;
  border-radius: 2px;
  text-align: center;
`

const CREATE_CONTACT = gql`
  mutation createContact($contact: ContactInput!) {
    createContact(contact: $contact) {
      id
      data {
        encryptedData
        iv
      }
    }
  }
`

function CreateContactForm() {
  const [createContact, { loading }] = useMutation(CREATE_CONTACT)
  const [requestError, setRequestError] = useState()
  const { register, handleSubmit } = useForm()
  const { getAccount } = useAccount()
  const history = useHistory()

  async function onSubmit(fields) {
    try {
      setRequestError(false)
      fields.group = 'NETWORK'
      const { encryptionKey } = await getAccount()
      const data = await encryptObject(fields, encryptionKey)
      const result = await createContact({ variables: { contact: { data } } })
      history.push(`/contacts/${result.data.createContact.id}`)
    } catch (e) {
      console.error('Error occurred:', e)
      setRequestError('Hm, an error occurred. Try again.')
    }
  }

  return (
    <Container aria-labelledby="formTitle" onSubmit={handleSubmit(onSubmit)}>
      <Instruction id="formTitle">Enter contact details</Instruction>
      <Label>
        First name
        <Input type="text" name="firstName" ref={register} />
      </Label>
      <Label>
        Last name
        <Input type="text" name="lastName" ref={register} />
      </Label>
      {requestError && <RequestError role="alert">{requestError}</RequestError>}
      <Center>
        <Button disabled={loading}>
          Create Contact
          {loading && (
            <span role="progressbar">
              <FontAwesomeIcon icon={faSpinner} spin />
            </span>
          )}
        </Button>
      </Center>
    </Container>
  )
}

export default CreateContactForm
