import React from 'react'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import useDecryption from '../../hooks/useDecryption'
import AddNoteForm from '../notes/AddNoteForm'
import DunbarGroup from '../contacts/DunbarGroup'
import SearchBox from '../app/SearchBox'
import Loading from '../app/Loading'
import Error from '../app/Error'
import Note from '../notes/Note'

const Background = styled.div`
  position: absolute;
  background-color: #c4c4c4;
  height: 40px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const Container = styled.div`
  max-width: 320px;
  padding: 16px;
  margin: 0 auto;
`

const Name = styled.h1`
  margin: 32px 0 16px 0;
  text-align: center;
  line-height: 64px;
  font-weight: 600;
  font-size: 44px;
`

const GET_CONTACT = gql`
  query getContact($id: ID!) {
    getContact(id: $id) {
      id
      data {
        encryptedData
        iv
      }
      notes {
        id
        data {
          encryptedData
          iv
        }
      }
    }
  }
`

function ContactPage() {
  const { id } = useParams()
  const { data, loading, error } = useDecryption(
    useQuery(GET_CONTACT, {
      variables: { id }
    })
  )

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  const contact = data.getContact

  return (
    <>
      <Container>
        <SearchBox />
        <Link to="/">Back to contacts</Link>
        <Name>
          {contact.data.firstName} {contact.data.lastName}
        </Name>
        <DunbarGroup contact={contact} />
        {contact.notes.map(note => (
          <Note key={note.id} content={note.data.content} />
        ))}
        <AddNoteForm contact={contact.id} />
      </Container>
      <Background />
    </>
  )
}

export default ContactPage
