import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import ContactSummary from '../contacts/ContactSummary'
import useDecryption from '../../hooks/useDecryption'
import SearchBox from '../app/SearchBox'
import Loading from '../app/Loading'
import Error from '../app/Error'

const Background = styled.div`
  position: absolute;
  background-color: #c4c4c4;
  height: 40px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const Container = styled.div`
  max-width: 320px;
  padding: 16px;
  margin: 0 auto;
`

const ButtonBox = styled.div`
  margin-top: 16px;
  margin-left: -32px;
  display: flex;
`

const Button = styled(Link)`
  background-color: #fafafc;
  border: 1px solid #121232;
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  margin-left: 32px;
  height: 40px;
  width: 100%;

  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  line-height: 38px;
  color: #121232;
`

const GET_CONTACTS = gql`
  query getContacts {
    getContacts {
      id
      data {
        encryptedData
        iv
      }
    }
  }
`

function HomePage() {
  const { data, loading, error } = useDecryption(useQuery(GET_CONTACTS))

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  const contacts = data.getContacts

  return (
    <>
      <Container>
        <SearchBox />
        <ButtonBox>
          <Button to="/contacts/new">Add Contact</Button>
          <Button to="/">Add Event</Button>
        </ButtonBox>
        {contacts.map(contact => (
          <ContactSummary key={contact.id} contact={contact} />
        ))}
      </Container>
      <Background />
    </>
  )
}

export default HomePage
